import Prism from 'prismjs';
import {HamburgerMenu} from './HamburgerMenu'
import {LessonTabs} from './LessonTabs'
import './CookieConsent'
import StripeAddressInput from './inputs/StripeAddressInput';



Prism.highlightAll();

HamburgerMenu()
LessonTabs()
