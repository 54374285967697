function LessonTabs() {
    const defaultTab = (document.location.hash.length > 0) ? document.location.hash.split('#')[1] : 'notes';

    let tabs = document.getElementsByClassName('tab-btn')

    function changeTab(tab) {
        let transcriptContent = document.getElementById('transcript')
        let noteContent = document.getElementById('notes')

        let transcriptTab = document.getElementById('tab-transcript')
        let notesTab = document.getElementById('tab-notes')

        if(!transcriptContent || !noteContent ||  !transcriptTab || !notesTab){
            return;
        }

        if (tab === 'notes') {
            transcriptContent.hidden = true;
            noteContent.hidden = false;

            notesTab.children[0].classList.add('bg-pink-600')
            notesTab.children[0].classList.remove('bg-howtocodewell')

            transcriptTab.children[0].classList.remove('bg-pink-600')
            transcriptTab.children[0].classList.add('bg-howtocodewell')

        } else {
            transcriptContent.hidden = false;
            noteContent.hidden = true;

            notesTab.children[0].classList.add('bg-howtocodewell')
            notesTab.children[0].classList.remove('bg-pink-600')

            transcriptTab.children[0].classList.add('bg-pink-600')
            transcriptTab.children[0].classList.remove('bg-howtocodewell')

        }
    }


    if(defaultTab  !== null) {
        changeTab(defaultTab)
    }
    for (var i = 0; i < tabs.length; i++) {
        var tabBtn = tabs[i]

        tabBtn.addEventListener('click', function (event) {

            var selectedTab = this.getAttribute('href').split('#')[1];
            changeTab(selectedTab)
        })
    }

}
export {LessonTabs}
