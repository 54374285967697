import XHRWrapper from "../XhrWrapper";
import { BtnClick } from "./BtnInput";

function bind(scope, fn) {
    return function() {
       return fn.apply(scope, arguments);
    }
 }

class StripeAddressInput {

    constructor(formId) {
        this.formId = formId;

        console.log('Setting formId = ' + formId);

        let form = document.getElementById(this.formId);

        if (form) {
            console.log('Form is ready for input');
            form.addEventListener("submit", bind(this, this.handleSubmit), false);
        } else {
            console.log('Cannot find form with id '+ formId);
        }
    }

    mountStripeAddressElement(elementId, addressData) {
        console.log('Mounting Stripe address element');
        const style = {
            theme: 'stripe',
            variables: {
                spacingUnit: '4px',
                gridColumnSpacing: '20px',
                gridRowSpacing: '20px'
            }
    
        };
    
        const options = {
            appearance: style
        };

        console.log('Element ID to mount = ' + elementId);
        console.log('Address data to load');
        console.log(addressData);

        this.elements = stripe.elements(options);
        const addressElement = this.elements.create("address", addressData);
        addressElement.mount("#" + elementId);
    }

    handleSubmit = function(event) {

        BtnClick();

        console.log('Handling submit');
        const location = event.target.action;
        const addressElement = this.elements.getElement('address');

        const successCallback = function() {
            console.log('Refreshing page');
            BtnClick('saveBtn', 'spinner', 'button-text', 'Refreshing');
            window.location.reload(true);
        }

        addressElement.getValue().then(function(result) {
            if (result.complete) {
                console.log('Submitting form');
                const xhr = new XHRWrapper();
                xhr.send(location, result, 'POST', successCallback);
            } else {
                console.log('Error');
            }
        });

        event.preventDefault();
        return false;

    }

}

export default StripeAddressInput;