class XHRWrapper {

    send(location, data, method = 'POST', successCallback) {
        console.log('Making ' + method + ' XHR request to ' + location)
        const xhr = new XMLHttpRequest();
        xhr.open(method, location);
        xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
        const body = JSON.stringify({data: data});
        xhr.onload = () => {
            console.log(xhr);
            if (xhr.readyState === 4 && (xhr.status === 201 || xhr.status === 200 )) {
                console.log(JSON.parse(xhr.responseText));
                successCallback(JSON.parse(xhr.responseText));
                return true;
            } else {
                console.log(`Error: ${xhr.status}`);
                return false;
            }
        };
        console.log('Sending request');
        xhr.send(body);
    }
}

export default XHRWrapper;
