function HamburgerMenu () {
  // Hamburger menu
  const hamburgerMenu = document.getElementById('main-menu-nav-toggle')
  const navContent = document.getElementById('main-menu-nav-content')
  hamburgerMenu.addEventListener('click', function () {
    const isNavHidden = navContent.classList.contains('hidden')
    if (isNavHidden) {
      navContent.classList.remove('hidden')
    } else {
      navContent.classList.add('hidden')
    }
  })
}
export { HamburgerMenu }