const BtnClick = function (btnId = 'saveBtn', spinnerId = 'spinner', buttonTxtId = 'button-text', text = 'Saving...')
{
    console.log('Handling click');
    const submitBtn = document.getElementById(btnId);
    submitBtn.setAttribute('disabled', true);

    const buttonTxt = document.getElementById(buttonTxtId);
    const spinner = document.getElementById(spinnerId);
    spinner.classList.remove('hidden');
    buttonTxt.innerHTML = text;
}

const BtnClickEnable = function (btnId = 'saveBtn', spinnerId = 'spinner', buttonTxtId = 'button-text', text = 'Save')
{
    console.log('Handling click');
    const submitBtn = document.getElementById(btnId);
    submitBtn.setAttribute('disabled', false);

    const buttonTxt = document.getElementById(buttonTxtId);
    const spinner = document.getElementById(spinnerId);
    spinner.classList.add('hidden');
    buttonTxt.innerHTML = text;
}


export { BtnClick, BtnClickEnable };